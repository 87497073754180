import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/utils/rem.js'

import Vant from 'vant';
import 'vant/lib/index.css';
// import VConsole from 'vconsole';

// if (process.env.NODE_ENV === 'development') {
  // new VConsole();
// }

Vue.use(Vant);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
