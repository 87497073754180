<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
 
}
</script>

<style>
html,
body {
  padding: 0;
  margin: 0;
}
#app {
  padding: 0;
  margin: 0;
}
</style>
